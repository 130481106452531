<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0">
			<h1 class="heading1 mx-1">Need a Demo?</h1>
		</div>
	</div>
	<div class="container px-0">
		<div class="d-flex justify-content-center">
			<img src="../../assets/images/about-us-ho.jpg" class="img-fluid" alt="Under Construction" />
		</div>
		<div class="heading1 text-center text-warning">Available on Demand!</div>
		<p class="pb-md-3 text-center">
			If you want me to demonstrate any task, please
			<a class="text-danger" [routerLink]="['/contact']" title="Contact Me for coding task Demo">Contact</a>
			me to see the output here.
		</p>
	</div>
</div>
