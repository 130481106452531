<div class="container-fluid px-0">
	<div class="py-2 pghdrDiv">
		<div class="container">
			<h1 class="d-flex justify-content-center heading1">Hi, I'm Venkatesh!</h1>
			<h1 class="d-flex justify-content-center heading1">
				<small style="font-size: 40%">(a Software Developer)</small>
			</h1>
		</div>
	</div>
	<div class="container my-lg-5 pb-lg-3">
		<div class="row my-3 justify-content-md-center">
			<div class="col-md-4">
				<div class="card card-link h-100 rounded-top" [routerLink]="['/about']" title="View About Me">
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/me.jpg"
							class="img-fluid rounded-top crdImg"
							alt="About Me"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">About Me</h3>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div
					class="card card-link h-100 rounded-top mt-2 mt-md-0"
					[routerLink]="['/portfolio']"
					title="View Portfolio"
				>
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/portfolio.jpg"
							class="img-fluid rounded-top crdImg"
							alt="Portfolio"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">Portfolio</h3>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div
					class="card card-link h-100 rounded-top mt-3 mt-md-0"
					[routerLink]="['/resume']"
					title="View Resume"
				>
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/cv.jpg"
							class="img-fluid rounded-top crdImg"
							alt="Resume"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">Resume</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="row my-4 my-md-3 justify-content-md-center">
			<div class="col-md-4">
				<div class="card card-link h-100 rounded-top" [routerLink]="['/website']" title="View About Website">
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/website2.jpg"
							class="img-fluid rounded-top crdImg"
							alt="About Website"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">About this Website</h3>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card card-link h-100 rounded-top mt-2 mt-md-0" [routerLink]="['/demo']" title="View Demos">
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/demo2.jpg"
							class="img-fluid rounded-top crdImg"
							alt="Demo"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">Demo</h3>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div
					class="card card-link h-100 rounded-top mt-3 mt-md-0"
					[routerLink]="['/contact']"
					title="View Contact Information"
				>
					<div class="card-body p-0 text-center crdImgTop">
						<img
							src="../../assets/images/dashboard/contact3.jpg"
							class="img-fluid rounded-top crdImg"
							alt="Contact Info"
						/>
					</div>
					<div class="card-footer border-top">
						<h3 class="text-center">Contact</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
