import { Component } from '@angular/core';

@Component({
	selector: 'app-resume',
	templateUrl: './resume.component.html'
})
export class ResumeComponent {
	constructor() {}

	isCollapsed: boolean;
}
