<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0">
			<h1 class="heading1 mx-1">Few of my recent Designs..</h1>
		</div>
	</div>
	<div class="d-none d-md-flex justify-content-center my-2 align-items-center">
		<i class="bi bi-info-circle pe-2"></i>Click on any image to zoom.
	</div>
	<div class="container px-0 my-3 my-lg-4">
		<lightgallery [settings]="settings" class="row mx-1">
			<div *ngFor="let img of portfolioImages" class="col-md-4 my-2">
				<div class="gallery-item" [attr.data-src]="img.img">
					<img [src]="img.img" class="img-thumbnail" [title]="img.desc" [alt]="img.desc" />
				</div>
			</div>
		</lightgallery>
	</div>
</div>
