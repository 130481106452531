<!-- <div id="content" class="d-flex flex-column h-100"> -->
<!--Header -->
<header class="d-flex align-items-center fixed-top">
	<button
		type="button"
		class="ml-1 btn navbar-toggler d-lg-none"
		data-bs-toggle="collapse"
		data-bs-target="#navbarNavDiv"
		aria-controls="navbarNavDiv"
		aria-label="Toggle Menu"
		aria-expanded="false"
		title="Toggle Menu"
	>
		<span class="text-white">&#9776;</span>
	</button>
	<div class="container text-center w-100" role="banner">
		<a
			[routerLink]="['/']"
			[routerLinkActive]="['active']"
			[routerLinkActiveOptions]="{ exact: true }"
			class="text-white text-decoration-none h1 bannerHdrTxt"
			title="Dashboard"
			>Venkatesh Dhanekula</a
		>
	</div>
</header>
<!--End of Header -->

<!-- Menubar -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark" role="navigation">
	<div class="container">
		<div class="navbar-collapse collapse" id="navbarNavDiv">
			<ul class="navbar-nav mx-auto">
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="Dashboard"
						>Home</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/about']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="About Me"
						>About</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/portfolio']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="View Portfolio"
						>Portfolio</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/resume']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="View & Download Resume"
						>Resume</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/website']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="About Website"
						>Website</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/demo']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="Demo"
						>Demo</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/contact']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						title="Contact Me"
						>Contact</a
					>
				</li>
			</ul>
		</div>
	</div>
</nav>
<!--End of Menubar -->

<!-- <div id="page-container" class="container-fluid flex-grow-1"> -->
<div id="page-container">
	<router-outlet></router-outlet>
</div>
<!-- Footer -->
<div
	class="scrollToTop text-center font-weight-bold position-fixed d-block text-decoration-none"
	[@scrollTopAppearInOut]="animationState"
	(click)="scrollToTop()"
	title="Scroll to Top"
>
	<i class="bi bi-arrow-up-circle-fill text-danger display-6"></i><br />
	<b class="text-dark">TOP</b>
</div>
<footer class="footer py-2 py-md-3 w-100 text-center">
	<div class="d-none">Last updated: 8-Aug-2023</div>
	<div>
		<span>&copy;</span> 2023 Venkatesh Dhanekula.
		<span class="d-block d-sm-inline">All rights reserved</span>
	</div>
</footer>
<!-- End of footer -->
<!-- </div> -->
