<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0 d-flex justify-content-between align-items-center">
			<h1 class="heading1 mx-1">Resume..</h1>
			<a
				href="../../assets/Resume_Venkatesh_Dhanekula.pdf"
				download="Resume_Venkatesh_Dhanekula.pdf"
				target="_blank"
				class="btn btn-sm btn-outline-danger float-end"
				title="Download PDF version of the Resume"
				><i class="bi bi-file-pdf-fill"></i> Download Resume</a
			>
		</div>
	</div>
	<div class="container px-0 my-3 my-lg-5">
		<div>
			<div class="mb-5">
				<p class="font-weight-bold mb-0 text-danger">Summary:</p>
				<ul class="mb-0">
					<li>
						Over 10+ years of experience in the full life cycle (SDLC) of the Software Development process
						including Requirements gathering, Analysis, Design, Development, UI development, UX design,
						Front-end development, media (screen, mobile, touch interface) compatibilities, Wireframes,
						Application architecture, Testing, Project and Team management of, Internet, E-Commerce,
						Electronics, Health Care, and Enterprise applications
					</li>
					<li>
						Well versed in creating cross-browser compatible web applications with responsive designs using
						both @media queries and frameworks like Twitter Bootstrap and Foundation JS which works on any
						browser, any device with any resolution.
					</li>
					<li>
						Experienced in implementing web applications using HTML5, CSS3, SCSS, Bootstrap, Angular
						1,5,6,7,8,9,10,11,12,13,14 TypeScript, Node JS, JavaScript, JQuery, JQuery UI, Kendo UI, and
						other latest JavaScript frameworks.
					</li>
				</ul>
				<ul class="mb-0 collapse" id="res_sum_exp">
					<li>
						Complete knowledge and experience in Angular features like Event binding, Property binding, data
						binding, Custom Directives, Pipes, Filters, Controllers, Services, CLI, Routes, lazy loading,
						and Project Architecture.
					</li>
					<li>
						Experienced in using Java technologies such as JSP, Java, Spring REST Services, Struts 2.x,
						Hibernate, and Weblogic.
					</li>
					<li>
						Expertise in using XML/JSON and AJAX to link with back-end applications using REST Services.
					</li>
					<li>
						Created Queries, Functions, Tables, and Stored Procedures in SQL, Oracle PL/SQL, MySQL using SQL
						Developer and Toad.
					</li>
					<li>
						Worked extensively with IDE’s like Visual Studio Code, Eclipse, Sublime Text Editor, Notepad++,
						Visual Studio Code, and Adobe Dreamweaver.
					</li>
					<li>
						Expertise in using debugging tools like Firebug, Chrome, IE and built in IDE debuggers for
						troubleshooting existing code.
					</li>
					<li>
						Experienced in all phases of usability testing including user profiles and personals, task
						analysis, contextual research, test moderation, and reporting.
					</li>
					<li>
						Interacted with the Stakeholders, Product Owners, and Business Analysts to identify the needs
						and requirements of the system, and to convert the business requirements into functional
						specifications.
					</li>
					<li>
						Experienced working in both AGILE and SDLC based development environment and participating in
						SCRUM sessions, Sprint planning, backlog grooming, program increment planning meetings.
					</li>
					<li>
						Well versed in using Atlassian tools such as JIRA, Bitbucket, Bamboo, Confluence, and Crucible.
					</li>
					<li>Participated in CMMI SCAMPI B & SCAMPI A appraisals.</li>
					<li>
						Performed exceptionally well while working in multiple projects, in startup environments and in
						mission critical projects even with high levels of stress.
					</li>
					<li>Experienced in managing and coordinating both on-site and offshore teams.</li>
					<li>Capable of performing in a fast paced, result driven atmosphere.</li>
					<li>
						Self-starter, highly motivated team- player with excellent communication, problem-solving
						skills, quick learning ability, easily adaptable to new environments.
					</li>
				</ul>
				<button
					type="button"
					class="btn btn-md btn-link"
					[title]="!isCollapsed ? 'Read more...' : 'Read less...'"
					style="box-shadow: none"
					data-bs-toggle="collapse"
					data-bs-target="#res_sum_exp"
					aria-controls="res_sum_exp"
					aria-expanded="false"
					(click)="isCollapsed = !isCollapsed"
				>
					{{ !isCollapsed ? 'Read more...' : 'Read less...' }}
				</button>
			</div>
			<div class="my-md-5">
				<p class="font-weight-bold mb-0 text-danger">Achievements:</p>
				<ul>
					<li>
						Saved at least 1 million USD/year in 2016, 2017, 2018, & in 2019 by introducing innovative
						JavaScript frameworks which not only reduced the no of resources required to deliver a project
						but also drastically reduced the development timelines.
					</li>
					<li>
						Transitioned the entire company (30-year-old company) architecture from Server side Java based
						technologies (where almost all the developers are Senior level Java and db developers) to Client
						side technologies such as Angular, jQuery, Kendo UI, Bootstrap, and other JavaScript frameworks
						without any resistance from architecture team, managers, and developers.
					</li>
					<li>
						Trained around 75+ developers on the latest versions of the frontend frameworks to remove the
						dependencies.
					</li>
					<li>
						Created UI, UX, accessibility, SEO/Web standards/best-practices across the enterprise (30+
						applications) from an architecture level and guided, helped other development teams to follow
						them in order to achieve the consistency across the enterprise.
					</li>
					<li>
						Achieved 100% accessibility compliance with 0 accessibility errors and 0 warnings as per latest
						W3C WCAG 2 and Section 508 standards within 5 days for a massive 250 web page application.
						<small
							>(Achieving 0 warnings is extremely difficult and many developers take months to resolve
							major issues itself.)</small
						>
					</li>
					<li>Experienced in Working 3 projects at a time.</li>
				</ul>
			</div>

			<!-- Education -->
			<div>
				<p class="font-weight-bold mb-0 text-danger">Educational Qualifications:</p>
				<ul>
					<li>Master of Science in Information Technology from University of Central Missouri, U.S.A.</li>
					<li>
						Bachelor of Technology in Computer Science from Jawaharlal Nehru Technological University,
						India.
					</li>
					<li>Certified SAFe 5 (Scaled Agile Framework) Agilist</li>
					<li>Progress certified Kendo UI Developer.</li>
				</ul>
			</div>
			<!-- End of the Education -->

			<!-- Technical Skills -->
			<div class="my-md-5">
				<p class="font-weight-bold mb-0 text-danger">Technical Skills:</p>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<tbody>
							<tr>
								<th class="res_skill_thWidth">Client-side Technologies</th>
								<td>
									HTML/HTML5, CSS/CSS3, SASS/SCSS, JavaScript, JQuery, Angular 1,5,6,7,8, TypeScript,
									Bootstrap, Foundation, Node JS, Kendo UI Angular, Kendo UI JQuery, Kendo UI JSP,
									XML, AJAX JSON, UI Concept Modelling/Wire Framing
								</td>
							</tr>
							<tr>
								<th>Server-side Technologies</th>
								<td>JSP, Java, Spring REST Services, Struts 2.x, Hibernate, Maven</td>
							</tr>
							<tr>
								<th>Database</th>
								<td>Oracle 11g, 12c, PL/SQL, My SQL, SQL Server 2005/08/12, Toad</td>
							</tr>
							<tr>
								<th>Web/App Servers</th>
								<td>WebLogic, Node, IIS 6.0 / IIS 7.0 / IIS 8.0</td>
							</tr>
							<tr>
								<th>IDE’s</th>
								<td>
									Visual Studio Code, Eclipse, Sublime Text Editor, Notepad++, Visual Studio,
									Dreamweaver
								</td>
							</tr>
							<tr>
								<th>Version Control</th>
								<td>Git, Bitbucket, IBM ClearCase, ClearQuest, GitHub</td>
							</tr>
							<tr>
								<th>Operating Systems</th>
								<td>Windows (Family, Network & Enterprise), Fedora, Ubuntu, Linux Mint, Mac OS</td>
							</tr>
							<tr>
								<th>Methodologies</th>
								<td>Agile, SDLC, Scrum, SAFe (Scaled Agile Framework)</td>
							</tr>
							<tr>
								<th>Other Software Skills</th>
								<td>
									JIRA, Bamboo, Confluence, Crucible/Fisheye, Adobe LiveCycle Designer, MS Office,
									Visio, Adobe Photoshop, SharePoint, SortSite
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- End of the Technical Skills -->
		</div>
		<!-- Cisco -->
		<div class="my-5 mx-1">
			<div class="my-2">
				<svg xmlns="http://www.w3.org/2000/svg" width="108" height="57" viewBox="0 0 108 57" role="img">
					<title id="fwt-logo-title">Cisco</title>
					<defs>
						<path
							d="M0 22.769a2.348 2.348 0 0 0 3.935 1.545c.434-.403.7-.955.744-1.545v-5.2a2.34 2.34 0 0 0-4.679 0v5.2"
							id="bar_short"
							class="bar"
						></path>
						<path
							d="M12.95 22.769a2.349 2.349 0 0 0 2.34 2.171 2.346 2.346 0 0 0 2.339-2.171V11.112a2.341 2.341 0 0 0-4.679 0V22.77"
							id="bar_tall"
							class="bar"
						></path>
						<path
							d="M25.832 27.464a2.345 2.345 0 0 0 4.678 0V2.249a2.342 2.342 0 0 0-4.678 0v25.215"
							id="bar_grande"
							class="bar"
						></path>
						<path
							d="M24.026 56.277v-5.002l-.098.043a9.253 9.253 0 0 1-3.605.915 5.303 5.303 0 0 1-3.63-1.07 4.644 4.644 0 0 1-1.58-2.244 5.395 5.395 0 0 1-.106-3 4.6 4.6 0 0 1 1.609-2.566 4.823 4.823 0 0 1 2.528-1.09 8.332 8.332 0 0 1 4.774.895l.108.056v-5.03l-.228-.061a12.78 12.78 0 0 0-4.552-.596 10.534 10.534 0 0 0-4.065.93 9.29 9.29 0 0 0-3.329 2.572 10.014 10.014 0 0 0-.182 12.18 9.546 9.546 0 0 0 5.292 3.403c2.211.538 4.528.456 6.697-.234l.367-.101"
							id="cisco_c"
						></path>
					</defs>
					<g fill="#1BA0D7" fill-rule="evenodd">
						<use href="#cisco_c"></use>
						<use href="#cisco_c" x="49.5"></use>
						<path
							d="M41.69 52.125l-.019 4.308.315.054c.989.185 1.987.313 2.99.385a18.1 18.1 0 0 0 2.642.051c.842-.06 1.675-.218 2.48-.468a6.862 6.862 0 0 0 2.573-1.371 5.19 5.19 0 0 0 1.498-2.148 5.912 5.912 0 0 0-.03-4.324 4.852 4.852 0 0 0-1.343-1.862 5.568 5.568 0 0 0-1.97-1.147l-3.25-1.206a1.742 1.742 0 0 1-.887-.845 1.107 1.107 0 0 1 .036-.986 1.29 1.29 0 0 1 .217-.291 1.75 1.75 0 0 1 .48-.347c.363-.18.755-.293 1.158-.337a6.76 6.76 0 0 1 2.072.022c.81.088 1.613.231 2.402.43l.168.037v-3.974l-.31-.067a21.14 21.14 0 0 0-2.444-.435 12.545 12.545 0 0 0-3.213-.014 6.945 6.945 0 0 0-3.699 1.488 4.908 4.908 0 0 0-1.58 2.178 5.984 5.984 0 0 0-.003 4.05c.242.65.63 1.237 1.136 1.714.437.422.932.78 1.47 1.065.708.38 1.458.675 2.235.878.257.077.512.158.766.243l.385.141.11.045c.38.136.726.35 1.018.628.202.19.358.423.455.683.06.237.061.485.003.723a1.536 1.536 0 0 1-.744.892 3.691 3.691 0 0 1-1.239.387 9 9 0 0 1-1.92.097 21.973 21.973 0 0 1-2.507-.334c-.433-.09-.864-.19-1.291-.303l-.16-.04zM30.546 56.607h4.73V37.846h-4.73zM85.305 43.386a4.934 4.934 0 1 1 6.157 7.711 4.934 4.934 0 0 1-6.157-7.71m-6.867 3.848A9.87 9.87 0 0 0 90.44 56.72a9.629 9.629 0 0 0 3.157-17.729 9.934 9.934 0 0 0-15.16 8.244"
						></path>
						<use href="#bar_short" x="0"></use>
						<use href="#bar_tall" x="0"></use>
						<use href="#bar_grande" x="0"></use>
						<use href="#bar_tall" x="25.875"></use>
						<use href="#bar_short" x="51.75"></use>
						<use href="#bar_tall" x="51.75"></use>
						<use href="#bar_grande" x="51.75"></use>
						<use href="#bar_tall" x="77.625"></use>
						<use href="#bar_short" x="103.375"></use>
					</g>
				</svg>
				<p class="font-weight-bold mb-0">
					Senior Software Engineer
					<span class="projDuration">(Aug 2019 - Till today)</span>
				</p>
			</div>
			<div>
				<p class="projDesc mb-1">
					Cisco+ provides a simple way to use and gain value from Cisco products in an integrated,
					cloud-enabled, easy-to-consume experience using as-a-Service (NaaS) model.
				</p>
			</div>
		</div>
		<!-- End of the Cisco -->

		<!-- NCI -->
		<div class="my-5 mx-1">
			<div class="my-2">
				<img
					src="../../assets/images/resume/nci.png"
					class="img-fluid"
					title="National Cancer Institute, Washington D.C."
					alt="National Cancer Institute"
				/>
				<p class="font-weight-bold mb-0">
					Frontend Developer
					<span class="projDuration">(Aug 2015 - Aug 2019)</span>
				</p>
			</div>
			<div>
				<p class="projDesc mb-1">
					The National Cancer Institute’s CTEP-ESYS is one of the core enterprise system consists of around 30
					applications which supports Cancer Clinical Trails Evaluation for United States Federal government
					bodies such as National Cancer Institute (NCI), Food and Drug Administration (FDA) while integrating
					with other enterprise systems such as PMB, DCP, etc.
				</p>
			</div>
		</div>
		<!--End of NCI -->

		<!-- NIMHD -->
		<div class="my-5 mx-1">
			<div class="my-2">
				<img
					src="../../assets/images/resume/nimhd.png"
					class="img-fluid"
					title="National Institute on Minority Health and Health Disparities, Washington D.C."
					alt="National Institute on Minority Health and Health Disparities"
				/>
				<p class="font-weight-bold mb-0">
					Full stack Developer
					<span class="projDuration">(Jan 2015 - Aug 2015)</span>
				</p>
			</div>
			<div>
				<p class="projDesc mb-1">
					The NIMHD’s “My HealthBuddy” is a Hybrid Web Application created in partnership with University of
					Maryland for MSM community living in Baltimore, MD region with the AIDS virus. My HealthBuddy
					reduces the distance between the patients and Health Care Providers with various features like Chat,
					Video Call, Fitness Tracking, Health Education, Locators, Reminders, alerts, Health Records Storing
					& Sharing to Providers, Scheduling Appointments, etc.
				</p>
			</div>
		</div>
		<!-- End of NIMHD -->

		<!-- Samsung -->
		<div class="my-5 mx-1">
			<div class="my-2">
				<img
					src="../../assets/images/resume/samsung.png"
					class="img-fluid"
					title="Samsung Electronics America, India"
					alt="Samsung Electronics America"
				/>
				<p class="font-weight-bold mb-0">
					Software Developer
					<span class="projDuration"> (Apr 2011 - Dec 2013)</span>
				</p>
			</div>
			<div>
				<p class="projDesc mb-1">
					The Harmony workforce optimization software suite is a secure web-based platform that is seamlessly
					integrated from end-to-end to provide the ultimate in flexibility, scalability and ease of use.
					Harmony is deployed in hundreds of mission-critical environments across the globe. With the
					invaluable insights, organizations can increase productivity, reduce attrition, enhance customer
					satisfaction, and improve workflow.
				</p>
			</div>
		</div>
		<!--End of Samsung -->

		<div>
			<p class="text-danger text-center pb-5">
				Want to read more,
				<a
					href="../../assets/Resume_Venkatesh_Dhanekula.pdf"
					download="Resume_Venkatesh_Dhanekula.pdf"
					target="_blank"
					class="text-danger"
					title="Download PDF version of the Resume"
					>Download Resume</a
				>
			</p>
		</div>
	</div>
</div>
