import { Component, HostListener, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	animations: [
		trigger('scrollTopAppearInOut', [
			state('in', style({ display: 'block', opacity: '0.85' })),
			state('out', style({ display: 'none', opacity: '0' })),
			transition('in => out', animate('100ms ease-in-out')),
			transition('out => in', animate('100ms ease-in-out'))
		]),
		trigger('collapse', [
			state(
				'open',
				style({
					opacity: '1'
					//transform: 'translate3d(0, 0, 100%)'
				})
			),
			state(
				'closed',
				style({
					opacity: '0',
					//transform: 'translate3d(0, 0, 0)',
					display: 'none'
				})
			),
			transition('closed => open', animate('100ms ease-in')),
			transition('open => closed', animate('100ms ease-out'))
		])
	]
})
export class AppComponent implements OnInit {
	title = 'Venkatesh';
	/* Scroll to Top  */
	scrollToTop() {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	}

	animationState: string = 'out';
	@Input() scrollDistance: number = 100;
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (this.isBrowser()) {
			this.animationState = this.getCurrentScrollTop() > this.scrollDistance ? 'in' : 'out';
		}
	}
	isBrowser(): boolean {
		return typeof window !== 'undefined';
	}
	getCurrentScrollTop() {
		if (typeof window.scrollY !== 'undefined' && window.scrollY >= 0) {
			return window.scrollY;
		}
		if (typeof window.pageYOffset !== 'undefined' && window.pageYOffset >= 0) {
			return window.pageYOffset;
		}
		if (typeof document.body.scrollTop !== 'undefined' && document.body.scrollTop >= 0) {
			return document.body.scrollTop;
		}
		if (typeof document.documentElement.scrollTop !== 'undefined' && document.documentElement.scrollTop >= 0) {
			return document.documentElement.scrollTop;
		}
		return 0;
	}
	/* End of the Scroll to Top  */

	ngOnInit() {
		this.onResize(window);
	}
	@HostListener('window:resize', ['$event.target'])
	onResize(event) {
		const links = document.getElementsByClassName('nav-link');
		if (event.innerWidth < 992) {
			Array.from(links).forEach(link => {
				link.setAttribute('data-bs-toggle', 'collapse');
				link.setAttribute('data-bs-target', '#navbarNavDiv');
				link.setAttribute('aria-controls', 'navbarNavDiv');
				link.setAttribute('aria-expanded', 'false');
			});
		} else {
			Array.from(links).forEach(link => {
				link.removeAttribute('data-bs-toggle');
				link.removeAttribute('data-bs-target');
				link.removeAttribute('aria-controls');
				link.removeAttribute('aria-expanded');
			});
		}
	}
	/*End of the Menu Collapse  */
}
