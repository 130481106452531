import { Component } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';

@Component({
	selector: 'app-portfolio',
	templateUrl: './portfolio.component.html'
})
export class PortfolioComponent {
	constructor() {}

	settings = {
		counter: false,
		selector: '.gallery-item',
		plugins: [lgZoom]
	};
	portfolioImages = [
		{
			img: '../assets/images/cisco/1.png',
			desc: 'Cisco+: Dashboard'
		},
		{
			img: '../assets/images/cisco/2.png',
			desc: 'Cisco+: Services'
		},
		{
			img: '../assets/images/cisco/3.png',
			desc: 'Cisco+: Sites'
		},
		{
			img: '../assets/images/cisco/4.png',
			desc: 'Cisco+: Sites'
		},
		{
			img: '../assets/images/cisco/5.png',
			desc: 'Cisco+: Site Details'
		},
		{
			img: '../assets/images/cisco/6.png',
			desc: 'Cisco+: Devices'
		},
		{
			img: '../assets/images/cisco/7.png',
			desc: 'Cisco+: Device Expanded Tile'
		},
		{
			img: '../assets/images/cisco/8.png',
			desc: 'Cisco+: Device Details'
		},
		{
			img: '../assets/images/cisco/9.png',
			desc: 'Cisco+: Devices'
		},
		{
			img: '../assets/images/cisco/10.png',
			desc: 'Cisco+: Tenants'
		},
		{
			img: '../assets/images/cisco/11.png',
			desc: 'Cisco+: Settings'
		},
		{
			img: '../assets/images/cisco/12.png',
			desc: 'Cisco+: Templates'
		},
		{
			id: 0,
			img: '../assets/images/portfolio/1.jpg',
			desc: 'Aurora: Login Screen'
		},
		{
			id: 1,
			img: '../assets/images/portfolio/18.jpg',
			desc: 'Aurora: Dashboard'
		},
		{
			id: 2,
			img: '../assets/images/portfolio/2.jpg',
			desc: 'Aurora: Dashboard'
		},
		{
			id: 3,
			img: '../assets/images/portfolio/3.jpg',
			desc: 'Aurora: Drug Accountability Log'
		},
		{
			id: 4,
			img: '../assets/images/portfolio/4.jpg',
			desc: 'Adverse Event Reporting System: Dashboard'
		},
		{
			id: 5,
			img: '../assets/images/portfolio/5.jpg',
			desc: 'Adverse Event Reporting System: 24hr Notifications'
		},
		{
			id: 6,
			img: '../assets/images/portfolio/6.jpg',
			desc: 'Adverse Event Reporting System: Reporting Screen'
		},
		{
			id: 7,
			img: '../assets/images/portfolio/7.jpg',
			desc: 'Registration & Credential Repository System: Approval Screen'
		},
		{
			id: 8,
			img: '../assets/images/portfolio/8.jpg',
			desc: 'Registration & Credential Repository System: Investigator Home'
		},
		{
			id: 9,
			img: '../assets/images/portfolio/10.jpg',
			desc: 'HPRC: Patient Questions'
		},
		{
			id: 10,
			img: '../assets/images/portfolio/11.jpg',
			desc: 'HPRC: Patient Questions'
		},
		{
			id: 11,
			img: '../assets/images/portfolio/12.jpg',
			desc: 'CTEP IAM: Password Reset Screen'
		},
		{
			id: 12,
			img: '../assets/images/portfolio/19.jpg',
			desc: 'Aurora: Dashboard for Mobile Screens'
		},
		{
			id: 13,
			img: '../assets/images/portfolio/13.jpg',
			desc: 'MyHealthBuddy: Dashboard'
		},
		{
			id: 14,
			img: '../assets/images/portfolio/14.jpg',
			desc: 'MyHealthBuddy: Fitness Info'
		},
		{
			id: 15,
			img: '../assets/images/portfolio/15.jpg',
			desc: 'MyHealthBuddy: Health Info'
		},
		{
			id: 16,
			img: '../assets/images/portfolio/16.jpg',
			desc: 'MyHealthBuddy: Location Finder'
		},
		{
			id: 17,
			img: '../assets/images/portfolio/17.jpg',
			desc: 'MyHealthBuddy: Notifications'
		}
	];
}
