import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoComponent } from './demo/demo.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ResumeComponent } from './resume/resume.component';
import { WebsiteComponent } from './website/website.component';

const routes: Routes = [
	{ path: 'resume', component: ResumeComponent },
	{ path: 'portfolio', component: PortfolioComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'website', component: WebsiteComponent },
	{ path: 'contact', component: ContactComponent },
	{ path: 'demo', component: DemoComponent },
	{ path: '', component: DashboardComponent },
	{ path: '**', component: DashboardComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
