<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0">
			<h1 class="heading1 mx-1">A little bit About me..</h1>
		</div>
	</div>
	<div class="container px-0 my-3 my-lg-4">
		<div class="aboutDesc mx-1">
			<p>
				<b>Ahoy there!</b>
				<img style="width: 40px" src="../../assets/images/hi.png" class="img-fluid" alt="Hi" />
			</p>
			<p>
				Thank you for visiting my website. As you might have noticed by now that, my name is Venkatesh Dhanekula
				and I'm a Software Developer.
			</p>
			<p>
				<img class="me-2" style="width: 50px" src="../../assets/images/india.png" />I born & brought up in
				Vijayawada, India. I did my Bachelors in Computer Science from JNTU University, India and worked few
				years for Samsung Electronics.
			</p>
			<p>
				<img
					src="../../assets/images/venkatesh.jpg"
					alt="Venkatesh Photo"
					class="col-12 col-sm-5 col-md-3 img-responsive float-end ml-3 mb-3 mb-md-1 img-thumbnail p-0"
				/>
				<img class="me-2" style="width: 50px" src="../../assets/images/usa.png" />I moved to United States back
				in 2013 and completed my
				<a
					target="_new"
					title="Visit University Website"
					class="text-danger"
					href="https://www.ucmo.edu/academics/programs/masters-degrees/computer-information-systems-and-information-technology-ms-53-627/index.php"
					>Masters in Information Technology
				</a>
				from University of Central Missouri in Kansas City. After completing Masters, I worked on various U.S.
				Federal Government projects specifically on Health Care related, such as AIDS Clinical Research and
				Cancer Clinical Trials projects with National Institute on Minority Health and Health Disparities
				(NIMHD), and National Cancer Institute (NCI) based in Washington DC area.
			</p>
			<p>
				<img class="me-2" style="width: 50px" src="../../assets/images/canada.png" />After spending more than 5
				years in U.S.A, I moved to Canada back in 2019. I'm currently living in Ottawa area and working with
				Cisco on its next big thing
				<a
					target="_new"
					title="More about Project"
					class="text-danger"
					href="https://www.cisco.com/c/en/us/products/plus-as-a-service.html"
				>
					Cisco+</a
				>
				Project.<br /><br />
				I'm a continuous learner and challenge myself everyday to push my limits further. I'm very passionate
				about solving the complex real-world problems through technology. In my free time, I like to read books
				(especially on Business, Investments, Leadership, and Biographies), visit new places, watch movies, try
				cooking new dishes, and hangout with friends. I drove Coast to Coast to Coast in Canada from Cape Spear
				in NL, the eastern most point in Atlantic Coast to Victoria in BC, the Western most point in Pacific
				Coast to Tuktoyaktuk in NT, the Northern most drivable point in Arctic Coast around 26,000 Kms and
				visited all major cities, provinces, and territories in Canada.
			</p>
		</div>
	</div>
</div>
