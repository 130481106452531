<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0">
			<h1 class="heading1 mx-1">Technologies used in this Website..</h1>
		</div>
	</div>
	<div class="container px-0">
		<div class="row mx-0 my-4 mb-md-2">
			<div class="col-sm-6 col-md-3">
				<div class="card h-100">
					<div class="card-body p-0 text-center crdImgTop">
						<img src="../../assets/images/tech/angular.png" class="img-fluid w-100" alt="Angular" />
					</div>
					<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 16.x</div>
				</div>
			</div>
			<div class="col-sm-6 col-md-3">
				<div class="card h-100 mt-2 mt-md-0">
					<div class="card-body p-0 text-center crdImgTop">
						<img src="../../assets/images/tech/typescript.png" class="img-fluid w-100" alt="TypeScript" />
					</div>
					<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 4.9</div>
				</div>
			</div>
			<div class="col-sm-6 col-md-3">
				<div class="card h-100 mt-3 mt-md-0">
					<div class="card-body p-0 text-center crdImgTop">
						<img src="../../assets/images/tech/bootstrap.png" class="img-fluid w-100" alt="Bootstrap" />
					</div>
					<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 5.2</div>
				</div>
			</div>
			<div class="col-sm-6 col-md-3">
				<div class="card h-100 mt-4 mt-md-0">
					<div class="card-body p-0 text-center crdImgTop">
						<img src="../../assets/images/tech/sass.png" class="img-fluid w-100" alt="SCSS" />
					</div>
					<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 4.12</div>
				</div>
			</div>
		</div>
		<div class="row my-4 my-md-2 mx-0">
			<div class="col-md-6">
				<div class="row mx-0 my-2">
					<div class="col-sm-12">
						<div class="card h-100">
							<div class="card-body p-2 text-center crdImgTop">
								<img
									src="../../assets/images/tech/html2.png"
									class="img-fluid w-100"
									alt="HTML 5, CSS3, JavaScript ES6"
								/>
							</div>
							<div class="card-footer px-0 py-1 border-top text-center h5 m-0">
								HTML 5, CSS 3, JavaScript ES6
							</div>
						</div>
					</div>
					<div class="col-sm-6 mt-2">
						<div class="card h-100">
							<div class="card-body p-0 text-center crdImgTop">
								<img src="../../assets/images/tech/nodejs.png" class="img-fluid w-100" alt="Node JS" />
							</div>
							<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 14.18</div>
						</div>
					</div>
					<div class="col-sm-6 mt-3">
						<div class="card h-100">
							<div class="card-body p-2 text-center crdImgTop">
								<img src="../../assets/images/tech/npm.png" class="img-fluid w-100" alt="NPM JS" />
							</div>
							<div class="card-footer px-0 py-1 border-top text-center h5 m-0">Ver: 6.14</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="row my-1 mx-0">
					<div class="card h-100">
						<div class="card-header h3 mb-0 py-1 text-danger">Website Specialties</div>
						<div class="card-body p-0 crdImgTop">
							<ul class="pt-2 mb-0">
								<li class="h4">Single Page Application.</li>
								<li class="h4">Fully Responsive.</li>
								<li class="h4">Cross Browser Compatible.</li>
								<li class="h4">Cross Platform Compatible.</li>
								<li class="h4">Works on any device, any browser, and on any platform.</li>
								<li class="h4">WCAG 2 Accessible Complaint.</li>
								<li class="h4">Serverless & Cloud.</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="row mx-0 mb-md-3">
					<div class="col-sm-6 mt-2">
						<div class="card h-100">
							<div class="card-body p-1 text-center crdImgTop">
								<img
									src="../../assets/images/tech/cloud.jpg"
									class="img-fluid w-100"
									alt="Google Cloud"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
