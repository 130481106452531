import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LightgalleryModule } from 'lightgallery/angular';
import { NgModule } from '@angular/core';

import { AboutComponent } from './about/about.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ContactComponent } from './contact/contact.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DemoComponent } from './demo/demo.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ResumeComponent } from './resume/resume.component';
import { WebsiteComponent } from './website/website.component';

@NgModule({
	declarations: [
		AppComponent,
		DashboardComponent,
		ResumeComponent,
		PortfolioComponent,
		AboutComponent,
		WebsiteComponent,
		DemoComponent,
		ContactComponent
	],
	imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, HttpClientModule, LightgalleryModule],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {}
