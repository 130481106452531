<div class="container-fluid px-0">
	<div class="py-2 py-lg-4 pghdrDiv">
		<div class="container px-0">
			<h1 class="heading1 mx-1">Connect with Me..</h1>
		</div>
	</div>
	<div class="container px-0 pt-2 text-center">
		<div class="my-5">
			<div class="row my-2 mx-1 justify-content-md-center">
				<div class="col-md-4">
					<div class="card h-100">
						<div class="card-img-top text-center">
							<img src="../../assets/images/email.png" class="img-fluid" alt="Email" />
						</div>
						<div class="card-body text-center">
							<a href="mailto:hi@venkatesh.dev" class="h4 text-danger" title="Send Email"
								>hi@venkatesh.dev</a
							>
						</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="card h-100 mt-2 mt-md-0">
						<div class="card-img-top text-center">
							<img src="../../assets/images/gitouch.png" class="img-fluid" alt="Get in touch" />
						</div>
						<div class="card-body text-center">
							<a
								href="https://www.linkedin.com/in/dhanekula/"
								target="_blank"
								class="btn btn-outline-danger font-weight-bold"
								title="Connect with me on Linkedin"
								>Connect</a
							>
						</div>
					</div>
				</div>
			</div>
			<div class="row my-2 mx-1 justify-content-md-center">
				<div class="col-md-4">
					<div class="card h-100 mt-3 mt-md-0">
						<div class="card-img-top text-center">
							<img src="../../assets/images/location.png" class="img-fluid" alt="Location" />
						</div>
						<div class="card-body text-center">
							<a
								href="https://www.google.com/maps/place/Ottawa,+ON"
								target="_blank"
								class="h4 text-danger"
								title="Click here to see the location"
								>Ottawa, Canada</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
